import NicoleCompressor from '../../../../assets/images/modulePopUp/nicole/compressor/img.png';
import NicoleCompressor1 from '../../../../assets/images/modulePopUp/nicole/compressor/img_1.png';
import NicoleCompressor2 from '../../../../assets/images/modulePopUp/nicole/compressor/img_2.png';
import NicoleCompressor3 from '../../../../assets/images/modulePopUp/nicole/compressor/img_3.png';
import NicoleLayers from '../../../../assets/images/modulePopUp/nicole/layers/img.png';
import NicoleLayers1 from '../../../../assets/images/modulePopUp/nicole/layers/img_1.png';
import NicoleLayers2 from '../../../../assets/images/modulePopUp/nicole/layers/img_2.png';
import NicoleLayers3 from '../../../../assets/images/modulePopUp/nicole/layers/img_3.png';

import NicoleMemory from '../../../../assets/images/modulePopUp/nicole/memory/img.png';
import NicoleMemory1 from '../../../../assets/images/modulePopUp/nicole/memory/img_1.jpg';
import NicoleMemory2 from '../../../../assets/images/modulePopUp/nicole/memory/img_2.jpg';
import NicoleMemory3 from '../../../../assets/images/modulePopUp/nicole/memory/img_3.jpg';

import NicolePost from '../../../../assets/images/modulePopUp/nicole/post/img.png';

export const MODULE_TEXT = [
    [
        [

            <div>
                <div className="title">Extended Memory</div>
                <img src={NicoleMemory} alt="module" className="main-pic" />
                <p className="accent">
                    <span>PARTNERED MODULE</span>
                    . Extended Memory is one of the two modules created in collaboration with partnered artists.
                    It provides a wider range of artistic styles and techniques that have been gathered over
                    years of training Neura. This includes the styles of renowned artists, showcasing the potential
                    of AI creativity.
                </p>
                <div className="samples">
                    <p>
                        <b>Examples of paintings with Extended Memory module:</b>
                    </p>
                    <div className='pictures'>
                        <em>
                            <img src={NicoleMemory1} alt="sample1" />
                            <u>1 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleMemory2} alt="sample2" />
                            <u>2 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleMemory3} alt="sample3" />
                            <u>3 lvl</u>
                        </em>
                    </div>
                    <div className="title-sub">
                        Model serial number: 1A:VR011:500 \ Stage: t-Alpha \ Core: 5_beta
                    </div>
                    <p>
                        With more upgrades you unlock more unexpected and bizarre styles in works of your Android AND you
                        increase a chance of getting a painting in the style of one of partnered Artists:
                        <span>BakaArts</span>
                        ,
                        <span>DeltaSauce</span>
                        ,
                        <span>Etiene Crauss</span>
                        ,
                        <span>Kolahon</span>
                        ,
                        <span>RedruM</span>
                        ,
                        <span>Pho</span>
                        .
                    </p>
                    <p>
                        This upgrade encourages your Android to look into the history of human art,
                        seeking inspiration in unpredictability for its works.
                    </p>
                    <p style={{ opacity: 0.5 }}>
                        DISCLAIMER: Module descriptions are guidelines; due to neural network unpredictability,
                        inconsistencies, and deviations may arise. There is always a place for unexpected.
                    </p>
                </div>
            </div>,
        ],
        [
            <div>
                <div className="title">Compressors</div>
                <img src={NicoleCompressor} alt="module" className="main-pic" />
                <p>
                    Compressors contain a combination of both styles and Neura’s own understanding of objects
                    and plots. The module’s unique appeal lies in its ability to challenge neural
                    networks with more abstract and minimalistic directions, compelling them to generate
                    unique artworks that transcend any trends.
                </p>
                <div className="samples">
                    <p>
                        <b>Examples of paintings with Compressors module:</b>
                    </p>
                    <div className='pictures'>
                        <em>
                            <img src={NicoleCompressor1} alt="sample1" />
                            <u>1 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleCompressor2} alt="sample2" />
                            <u>2 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleCompressor3} alt="sample3" />
                            <u>3 lvl</u>
                        </em>
                    </div>
                    <div className="title-sub">
                        Model serial number: BAM00-23LE\ Stage: t-Alpha \ Core: 5_beta
                    </div>
                    <p>
                        With more upgrades, plots become less figurative, increasing the rarity of the paintings
                        due to the extended processing power, required by the neural networks.
                        This additional time allows an Android to craft each piece meticulously,
                        transforming the limited input details into something truly unique, eternal
                        and existing in its own universe.
                    </p>
                    <p>
                        The Compressors Module for Neura offers a different approach to artistic expression,
                        emphasizing the potential of beauty and rarity in simplicity.
                    </p>
                    <p style={{ opacity: 0.5 }}>
                        DISCLAIMER: Module descriptions are guidelines; due to neural network unpredictability,
                        inconsistencies, and deviations may arise. There is always a place for unexpected.
                    </p>
                </div>
            </div>,
        ],
        [
            <div>
                <div className="title">Additional Layers</div>
                <img src={NicoleLayers} alt="module" className="main-pic" />
                <p>
                    Additional Layers give the owner of the Android much more control over the artworks.
                    Applying this module widens the range of emotions and moods used in paintings.
                    This Module does not affect the quality of paintings, but it lets you decide weather
                    you want to experiment with expanding the range of intense and extreme emotions conveyed
                    on canvas. This allows you to see Neura’s understanding of human emotions and feeling
                    through it own lens.
                </p>
                <div className="samples">
                    <p>
                        <b>Examples of paintings with Additional Layers module:</b>
                    </p>
                    <div className='pictures'>
                        <em>
                            <img src={NicoleLayers1} alt="sample1" />
                            <u>1 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleLayers2} alt="sample2" />
                            <u>2 lvl</u>
                        </em>
                        <em>
                            <img src={NicoleLayers3} alt="sample3" />
                            <u>3 lvl</u>
                        </em>
                    </div>
                    <div className="title-sub">
                        Model serial number: 00006-01129 \ Stage: t-Alpha \ Core: 5_beta
                    </div>
                    <p>
                        Initially Neura doesn’t lean towards any specific emotions aiming to stay “neutral” in
                        order to focus primarily on the plot. By applying this module you add more layers to
                        its generative process, creating another parallel workflow with a large pre-trained data
                        that adds more variety in emotions translated by Neura.
                    </p>
                    <p>
                        This results in beautiful and sometimes unsettling masterpieces where one can observe how
                        AI in order to recognize emotions puts them on extreme edges. You can also find a completely
                        unexpected combinations of plots and emotions which stands to Neura’s testimony: to amaze and
                        surprise.
                    </p>
                    <p style={{ opacity: 0.5 }}>
                        DISCLAIMER: Module descriptions are guidelines; due to neural network unpredictability,
                        inconsistencies, and deviations may arise. There is always a place for unexpected.
                    </p>
                </div>
            </div>,
        ],
        [
            <div>
                <div className="title">POST PROCESSOR</div>
                <img src={NicolePost} alt="module" className="main-pic" />
                <p>
                    Post Processors hold the knowledge of hundreds of years of fast painting techniques.
                    It allows you to reduce the number of days required to draw an artwork. Initially,
                    it takes 7 days for the first generation and more for the next ones, but using the
                    module can reduce this number.
                </p>
                <div className="samples">
                    <p>
                        <b>Formula to calculate the amount of days to create a painting:</b>
                    </p>
                    <div className='formula'>
                        ƒ(n) = (7 - module lvl) * 1.15
                        <sup> n</sup>
                    </div>
                    <div className="title-sub">
                        n is the number of the generation of the Android
                    </div>
                    <p>
                        Each module upgrade reduces the base deadline for painting (7 days) by 1 day.
                        Resulting in the fastest first generation with 4 upgrades (only for Nicole) reduced to only 3 days,
                        and the 5th generation in the same example would be 6 days instead of 14 days without any upgrades.
                    </p>
                    <p>
                        It is important to remember that each next generation is slower due to Android features,
                        so the sooner you apply the Processors module, the more paintings you will be able to get.
                    </p>
                </div>
            </div>,
        ],
    ],
];
